import React from "react";
import styled from "@emotion/styled";
import { LoadingAnimated } from "../LoadingAnimated/LoadingAnimated";

type ContainerType = "fullscreen" | "overlay" | "minimal" | "block";

const LoadingContainer = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullScreenContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${LoadingContainer}
`;

const BlockContainer = styled.div`
  width: 100%;
  height: 70vh;
  padding: 50px;
  ${LoadingContainer}
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: #ffffff80;
  ${LoadingContainer}
`;
const MinimalContainer = styled.div`
  padding: 50px;
  ${LoadingContainer}
`;

const BigLoadingIcon = styled(LoadingAnimated)`
  width: 5vh;
  height: 5vh;
`;

const Loading = ({
  containerType = "block",
  className,
}: {
  containerType?: ContainerType;
  className?: string;
}) => {
  const Container =
    containerType === "fullscreen"
      ? FullScreenContainer
      : containerType === "overlay"
      ? OverlayContainer
      : containerType === "minimal"
      ? MinimalContainer
      : BlockContainer;
  return (
    <Container data-testid="loading-container" className={className}>
      <BigLoadingIcon />
    </Container>
  );
};

export default Loading;
